<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table width="100%">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Extended Info</th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Asset Type</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.asset_type && detail.asset_type != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.asset_type }}</span>
                </template>
                <template v-else
                  ><span class="text-muted"> no asset type</span>
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Owner Ref. / Machine No.
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.owner_ref && detail.owner_ref != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.owner_ref }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">
                    no Owner Ref. / Machine No.
                  </span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Year</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail && detail.year && detail.year != 'null'">
                  <span class="text-capitalize">{{ detail.year }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no year</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Platform Height</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.plateform_height &&
                    detail.plateform_height != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.plateform_height
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no plateform height</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Engine Type</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.engine_type && detail.engine_type != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.engine_type }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no engine type</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Capacity</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.capacity && detail.capacity != 'null'"
                >
                  <span class="text-capitalize">{{ detail.capacity }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no capacity</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Code</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail && detail.code && detail.code != 'null'">
                  <span class="text-capitalize">{{ detail.code }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no code</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Chassis Number</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.chassis_number &&
                    detail.chassis_number != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.chassis_number
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no chassis number</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Engine Number</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.engine_number &&
                    detail.engine_number != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.engine_number
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no engine number</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">LTA Plate No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.lta_plate_no &&
                    detail.lta_plate_no != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.lta_plate_no }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no lta plate no</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">NEA Plate No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.nea_plate_no &&
                    detail.nea_plate_no != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.nea_plate_no }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no nea plate no</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Original Fork C/W
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.originial_fork &&
                    detail.originial_fork != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.originial_fork
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no original fork c/w</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">NOA</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail && detail.noa && detail.noa != 'null'">
                  <span class="text-capitalize">{{ detail.noa }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no NOA</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">LP/LM Cert</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.lp_cert && detail.lp_cert != 'null'"
                >
                  <span class="text-capitalize">{{ detail.lp_cert }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no lp/lm cert.</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                LP Cert Inspection Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.equip_start_date &&
                    detail.equip_start_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.equip_start_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no LP cert. inspection date</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                LP Cert Expiry Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.equip_end_date &&
                    detail.equip_end_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.equip_end_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no LP cert expiry date</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Machinery All Risk
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.machinery_all_risk &&
                    detail.machinery_all_risk != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.machinery_all_risk
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no machinery all risk</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Hour meter</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.hour_meter && detail.hour_meter != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.hour_meter }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no hour meter</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Breakdown log</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.breakdown_log &&
                    detail.breakdown_log != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.breakdown_log
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no breakdown log</span></template
                >
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>

      <v-col cols="6" class="pb-0 pl-4" v-if="detail.type != 'cross-rent'">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative;border-left: 1px solid lightgray !important"
          v-if="allowPurchaseinfo"
        >
          <table width="100%">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Purchase Info</th>
            </tr>
            <!-- <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Currency</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.equip_cost_currency && detail.equip_cost_currency != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.equip_cost_currency }}</span>
                </template>
                <template v-else> no currency </template>
              </td>
            </tr> -->
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Cost</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.equip_cost && detail.equip_cost != 'null'
                  "
                >
                  <span class="text-capitalize">
                    <v-chip outlined text-color="" label small color="blue">
                      {{ detail.equip_cost }}
                      {{
                        detail?.equip_cost_currency &&
                        detail.equip_cost_currency != "null"
                          ? detail.equip_cost_currency
                          : null
                      }}
                    </v-chip>
                  </span>
                </template>
                <template v-else> no cost </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Purchase Invoice Number
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.purchase_invoice_number &&
                    detail.purchase_invoice_number != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.purchase_invoice_number
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no purchase invoice number</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Contract No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.contract_number &&
                    detail.contract_number != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.contract_number
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no contract no.</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Bought From</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.brought_from &&
                    detail.brought_from != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.brought_from }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no Bought From</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Finance Company</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.finance_company &&
                    detail.finance_company != 'null'
                  "
                >
                  <span class="text-capitalize">
                    <Barcode
                      route="supplier.detail"
                      :barcode="detail.finance_barcode"
                      :id="detail.finance_company"
                    >
                    </Barcode
                  ></span>
                </template>
                <template v-else>
                  <span class="text-muted">no finance company</span></template
                >
              </td>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Finance Company Name
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.finance_company_name &&
                    detail.finance_company_name != 'null'
                  "
                >
                  <span class="text-capitalize">
                    {{ detail.finance_company_name }}</span
                  >
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no finance company name</span
                  ></template
                >
              </td>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Finance Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.finance_start_date &&
                    detail.finance_start_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.finance_start_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no finance start date</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Finance End Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.finance_end_date &&
                    detail.finance_end_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.finance_end_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no finance end date</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance Company
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.insurance_company &&
                    detail.insurance_company != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.insurance_company
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no insurance company</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance Policy
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.insurance_policy &&
                    detail.insurance_policy != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.insurance_policy
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no insurance policy</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.insurance_start_date &&
                    detail.insurance_start_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.insurance_start_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no insurance start date</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance End Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.insurance_end_date &&
                    detail.insurance_end_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.insurance_end_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no insurance end date</span
                  ></template
                >
              </td>
            </tr>

            <tr>
              <th colspan="2" class="font-size-18 py-2">Import Info</th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Arrival Date</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.import_arrival_date &&
                    detail.import_arrival_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.import_arrival_date
                  }}</span>
                </template>
                <template v-else
                  ><span class="text-muted"> no import arrival date</span>
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Permit No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.import_permit_no &&
                    detail.import_permit_no != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.import_permit_no
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no permit no.</span>
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">BOL No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.import_bol_no &&
                    detail.import_bol_no != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.import_bol_no
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no nol no.</span>
                </template>
              </td>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">DO No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.do_no && detail.do_no != 'null'"
                >
                  <span class="text-capitalize">{{ detail.do_no }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no do no.</span>
                </template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
//import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        {
          title: "Model",
          key: "model",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        {
          title: "Contact For Item",
          key: "company_number",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    allowPurchaseinfo: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    // Status,
    Barcode,
  },
};
</script>
