<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative;"
        >
          <table width="100%">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Cross Rent Info</th>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Rental Cost</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_cost &&
                    detail.cross_rent_cost != 'null'
                  "
                >
                  <span class="text-capitalize">
                    <v-chip outlined text-color="" label small color="blue">
                      {{ detail.cross_rent_cost }}
                      {{
                        detail?.cross_rental_cost_currency &&
                        detail.cross_rental_cost_currency != "null"
                          ? detail.cross_rental_cost_currency
                          : null
                      }}
                    </v-chip>
                  </span>
                </template>
                <template v-else> no cost </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Rental Invoice Number
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_invoice_number &&
                    detail.cross_rent_invoice_number != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.cross_rent_invoice_number
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no rental invoice number</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">Contract No.</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_contract_number &&
                    detail.cross_rent_contract_number != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.cross_rent_contract_number
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no contract no.</span></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">Finance Company</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_finance_company &&
                    detail.cross_rent_finance_company != 'null'
                  "
                >
                  <span class="text-capitalize">
                    <Barcode
                      route="supplier.detail"
                      :barcode="detail.cross_rent_finance_barcode"
                      :id="detail.cross_rent_finance_company"
                    >
                    </Barcode
                  ></span>
                </template>
                <template v-else>
                  <span class="text-muted">no finance company</span>
                </template>
              </td>
            </tr>

            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Finance Company Name
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_finance_company_name &&
                    detail.cross_rent_finance_company_name != 'null'
                  "
                >
                  <span class="text-capitalize">
                    {{ detail.cross_rent_finance_company_name }}</span
                  >
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no finance company name</span
                  ></template
                >
              </td>
            </tr>

            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Finance Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_finance_start_date &&
                    detail.cross_rent_finance_start_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.cross_rent_finance_start_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no finance start date</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Finance End Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_finance_end_date &&
                    detail.cross_rent_finance_end_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.cross_rent_finance_end_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no finance end date</span></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance Company
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_insurance_company &&
                    detail.cross_rent_insurance_company != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.cross_rent_insurance_company
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no insurance company</span></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance Policy
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_insurance_policy &&
                    detail.cross_rent_insurance_policy != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.cross_rent_insurance_policy
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no insurance policy</span></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_insurance_start_date &&
                    detail.cross_rent_insurance_start_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.cross_rent_insurance_start_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no insurance start date</span
                  ></template
                >
              </td>
            </tr>
            <tr class="line-height5" v-if="false">
              <td class="font-size-18 font-weight-500 py-2">
                Insurance End Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_insurance_end_date &&
                    detail.cross_rent_insurance_end_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.cross_rent_insurance_end_date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted"
                    >no insurance end date</span
                  ></template
                >
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative;"
        >
          <table width="100%">
            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Supplier Rental Info
              </th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Supplier Name</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_supplier_name &&
                    detail.cross_rent_supplier_name != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.cross_rent_supplier_name
                  }}</span>
                </template>
                <template v-else> no supplier name </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Rental Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_start_date &&
                    detail.cross_rent_start_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.cross_rent_start_date)
                  }}</span>
                </template>
                <template v-else> no rental start date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Rental End Date</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.cross_rent_end_date &&
                    detail.cross_rent_end_date != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    formatDate(detail.cross_rent_end_date)
                  }}</span>
                </template>
                <template v-else> no rental end date </template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
//import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        {
          title: "Model",
          key: "model",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        {
          title: "Contact For Item",
          key: "company_number",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    // Status,
    Barcode,
  },
};
</script>
